import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { isMobileDevice } from './utils/deviceDetection';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('silicaport-web-app-b'));

const loadApp = async () => {

  if (isMobileDevice()) {
    const { default: MobileRoutes } = await import('./Routes/mobile_routes');
    root.render(
      <BrowserRouter>
        <MobileRoutes />
      </BrowserRouter>
    );
  } 
  else {
    const { default: DesktopRoutes } = await import('./Routes/desktop_routes');
    root.render(
      <BrowserRouter>
        <DesktopRoutes />
      </BrowserRouter>
    );
  }
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
loadApp().then(() => {
  // Measure performance (optional)
  reportWebVitals();
});
